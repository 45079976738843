@font-face {
  font-family: ZFTERMIN;
  src: url(./fonts/ZFTERMIN__.ttf);
}
@font-face {
  font-family: INTER_REGULAR;
  src: url(./fonts/Inter-Regular.ttf);
}
@font-face {
  font-family: INTER_LIGHT;
  src: url(./fonts/Inter-Light.ttf);
}
@font-face {
  font-family: INTER_EXTRABOLD;
  src: url(./fonts/Inter-ExtraBold.ttf);
}

/* Font Style */
.emp { color: #ff007a; }
.bold { 
  font-family: 'INTER_EXTRABOLD';
  font-weight: 800;
}
.light { 
  font-family: 'INTER_LIGHT';
  font-weight: 300;
}
.pixel { 
  font-family: "ZFTERMIN";
  font-weight: 100;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'INTER_REGULAR';
  font-weight: 400;
  background: #fff;
  -ms-overflow-style: none;
  scrollbar-width: none;
}
body::-webkit-scrollbar { display: none; }

code {
  font-family: 'INTER_REGULAR';
  font-weight: 400;;
}

.App {
  background: #fff;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  text-align: center;
  font-size: calc(10px + 2vmin);
  color: #5f6476;
}

/* Loading Screen */
.centermodal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background: rgba(0,0,0,0.1);
  backdrop-filter: blur(12px);
  z-index: 1000;
}
.wrapper {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

header {
  height: 60px;
  width: 100%;
  background: #fff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 999;
  overflow: auto;
}
.projectTitle {
  font-size: 16px;
  margin: 0 10px 0 20px;
  padding: 5px 10px;
  cursor: pointer;
}
.funcList {
  font-size: 16px;
  margin: 0 10px;
  padding: 5px 10px;
  border-radius: 10px;
  cursor: pointer;
}
.funcList:hover {
  background: rgb(221, 218, 222);
}

.navButton {
  width: fit-content;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.wallet {
  width: 180px;
  max-width: fit-content;
  margin: 0 14px;
  padding: 7px;
  box-shadow: 6px 6px 0 #00000040;
  font-size: 16px;
  letter-spacing: 0.5px;
}

.fieldBanner {
  width: 100%;
  min-height: 300px;
  margin-top: 50px;
  background: linear-gradient(139.73deg,#e5fdff,#f3efff);
}
.SubfieldBanner {
  margin: 30px 100px;
  font-size: 75px;
}
.headfield {
  height: 90%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

header::-webkit-scrollbar { display: none; }
.fieldBanner::-webkit-scrollbar { display: none; }
.mainprofile::-webkit-scrollbar { display: none; }
.noscroll::-webkit-scrollbar { display: none; }

.collection {
  padding-top: 35px;
  min-height: 100vh;
  height: fit-content;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: flex-start;
}

.nftCard {
  height: 400px;
  width: 350px;
  padding: 30px;
  margin: 20px 0;
  background: #f7f7f7;
  box-shadow: 6px 6px 0 #00000040;
  border: 1px solid rgb(227, 227, 227);
  border-radius: 16px;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.button {
  padding: 10px 25px;
  border-radius: 12px;
  background: #ff007a;
  font-size: 18px;
  color: #fff;
  cursor: pointer;
}
.button:hover {opacity: 0.9;}

.items {
  background: #fff;
  border: 1px solid rgb(227, 227, 227);
  border-radius: 16px;
  height: 100px;
  width: 250px;
  max-width: 45%;
  margin: 40px 0;
  font-size: 22px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.tile:hover {
  background-color: rgba(0, 0, 0, 1) !important;
  transform: scale(1.02);
}

.inFooterLeft {
  margin: 30px 50px;
  display: flex;
  flex-direction: column;
  text-align: left;
  font-size: 12px;
}
.inFooterRight {
  margin: 30px 50px;
  display: flex;
  flex-direction: column;
  text-align: right;
  font-size: 12px;
}

.welcome {
  margin-top: 100px;
  justify-content: center;
  flex-wrap: nowrap;
  font-size: 18px;
  background-image: url('../public/background/home.webp');
  background-repeat: round;
}
.motto {
  font-size: 44px;
}
#showoff {
  min-width: 400px;
  background: hsla(0,0%,100%,.05);
  border: none;
}
.hashtag {
  margin: 10px;
  width: fit-content;
  padding: 10px 20px;
  display: flex;
  align-items: center;
  font-size: 16px;
  color: #000;
  cursor: pointer;
}
.hashtag:hover {
  background: #ff007a;
  color: #fff;
  text-decoration: underline;
}
@keyframes blinking {
  25% { opacity: 1; }
  50% { opacity: 0.1; }
  75% { opacity: 1; }
}
@keyframes typing {
  from { width: 0; }
  to { width: 70%; }
}
.typed-out{
  letter-spacing: 1px;
  color: #fff;
  overflow: hidden;
  /*border-right: 0.4em solid #000;*/
  white-space: nowrap;
  font-size: 20px;
  width: 0;
  animation: typing 1s forwards, blinking 3s linear infinite;
}
.blink {
  letter-spacing: 1px;
  font-size: 20px;
  animation: typing 1s forwards, blinking 3s linear infinite;
}
.slotbox {
  overflow: scroll; 
  display: flex;
}
.slotlevel {
  right: 30px;
}
.slotlevel2 {
  right: 50px;
}

@media (max-width: 480px) {
  .projectTitle {
    margin: 0 5px;
    width: 25px;
    overflow: hidden;
  }
  .funcList {
    margin: 0;
    font-size: 14px;
  }
  .inFooterLeft {
    margin: 25px;
    font-size: 10px;
  }
  .inFooterRight {
    margin: 25px;
    font-size: 10px;
  }
  .welcome {
    margin-top: 0;
    justify-content: flex-start;
    flex-wrap: wrap;
    font-size: 10px;
    background-size: 1500px;
    background-repeat: unset;
  }
  #showoff {
    min-width: 320px;
  }
  .typed-out {
    font-size: 12px;
  }
  .hashtag {
    width: fit-content;
    padding: 5px 10px;
    font-size: 12px;
  }
  .SubfieldBanner {
    margin: 20px 50px;
    font-size: 35px;
  }
  .headfield {
    width: 100%;
  }
  .slotbox {
    width: 360px;
  }
  .slotlevel {
    right: 10px;
  }
  .slotlevel2 {
    right: 30px;
  }
}
@media (min-width: 481px) and (max-width: 1024px) {
  .projectTitle {
    margin: 0 5px;
    width: 25px;
    overflow: hidden;
  }
  .funcList {
    margin: 0;
    font-size: 14px;
  }
  .inFooterLeft {
    margin: 25px;
  }
  .inFooterRight {
    margin: 25px;
  }
  .welcome {
    justify-content: flex-start;
    background-size: 2500px;
    background-repeat: unset;
  }
  .motto {
    margin-top: 30px;
  }
  .SubfieldBanner {
    margin: 20px 50px;
    font-size: 35px;
  }
  .headfield {
    width: 100%;
  }
}
@media (min-width: 1025px) and (max-width: 1366px) {
  .motto {
    font-size: 70px;
  }
  #showoff {
    min-width: 450px;
  }
}